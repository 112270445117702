import { Options, Vue } from "vue-class-component";
import Modal from "@/components/modal/modal.vue";
import { EditRoleList as RoleList } from "@/pages/system/user/user";

@Options({
    name: "app-select-organization",
    components: {
        "app-modal": Modal,
    },
    props: {
        modelValue: Object,
        submitFunction: Function,
        title: {
            type: String,
            default: "配置用户角色",
        },
    },
    emits: ["update:modelValue"],
})
export default class SelectRole extends Vue {
    private modelValue: RoleList = null;
    private selectRoleModalElement: any;

    public async mounted(): Promise<void> {
        this.selectRoleModalElement = this.$refs.selectRoleModal;
    }

    get roleItems(): RoleList {
        return this.modelValue;
    }

    set roleItems(value: RoleList) {
        this.$emit("update:modelValue", value);
    }

    public openModal() {
        this.$nextTick(() => {
            this.selectRoleModalElement.openModal();
        }).then();
    }

    public closeModal() {
        this.selectRoleModalElement.closeModal();
    }

    public toggleModal() {
        this.$nextTick(() => {
            this.selectRoleModalElement.toggleModal();
        }).then();
    }
}
