import { Options, Vue } from "vue-class-component";
import DropdownSearch from "@/modules/dropdown-search/dropdown-search.vue";
import ContentHeader from "@/modules/content-header/content-header.vue";
import Alert from "@/components/alert/alert.vue";
import Modal from "@/components/modal/modal.vue";
import selectOrganization from "@/modules/select-role/select-role.vue";
import { useToast } from "vue-toastification";

@Options({
    name: "app-blank",
    components: {
        "app-content-header": ContentHeader,
        "app-dropdown-search": DropdownSearch,
        "app-alert": Alert,
        "app-modal": Modal,
        "app-select-organization": selectOrganization,
    },
})
export default class Blank extends Vue {
    private activeTab = "COMMON";
    private editModalModID = 0;
    private editModalTitle = "";
    private editModalSubmitButtonName = "";
    private toast = useToast();

    private setActiveTab(tab: string): void {
        this.activeTab = tab;
    }

    private addModalOpen(): void {
        this.editModalTitle = "添加组织";
        this.editModalSubmitButtonName = "添加";
        (this.$refs.editModal as any).openModal();
    }

    private modModalOpen(id: number): void {
        this.editModalModID = id;
        this.editModalTitle = "修改组织" + id;
        this.editModalSubmitButtonName = "修改";
        (this.$refs.editModal as any).openModal();
    }

    private editModalSubmit(): void {
        if (this.editModalTitle == "添加组织") {
            console.log("添加");
            this.toast.success("添加成功！");
        }
        if (this.editModalTitle == "修改组织" + this.editModalModID) {
            console.log("修改" + this.editModalModID);
            this.toast.success("修改成功！");
        }
    }

    private delAlert(id: number): void {
        this.editModalModID = id;
        console.log("删除" + this.editModalModID);
        (this.$refs.alert as any).openAlert(
            {
                type: "okAndNo",
                title: "删除组织" + id,
                message: "删除组织" + id,
            },
            () => {
                console.log("删除" + this.editModalModID);
                this.toast.success("删除成功！");
            },
        );
    }
}
